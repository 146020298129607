// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xr5iV2e6v"];

const serializationHash = "framer-06ZNu"

const variantClassNames = {xr5iV2e6v: "framer-v-v8uhv9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, RoarXnRWq: title ?? props.RoarXnRWq ?? "Free & Paid"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RoarXnRWq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xr5iV2e6v", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-v8uhv9", className, classNames)} data-border data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"xr5iV2e6v"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-7858affe-da45-415e-8fc9-01499434ab14, rgb(198, 200, 201)) /* {\"name\":\"white-300\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0NsYXNoIEdyb3Rlc2sgU2VtaWJvbGQ=", "--framer-font-family": "\"Clash Grotesk Semibold\", \"Clash Grotesk Semibold Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-letter-spacing": "0.1em", "--framer-line-height": "1em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-7858affe-da45-415e-8fc9-01499434ab14, rgb(198, 200, 201)))", "--framer-text-transform": "uppercase"}}>Free &amp; Paid</motion.p></React.Fragment>} className={"framer-12i2j1r"} data-framer-name={"Title"} fonts={["CUSTOM;Clash Grotesk Semibold"]} layoutDependency={layoutDependency} layoutId={"xr5iV2e6vI2328:3061;2328:2953;2322:5112"} style={{"--extracted-r6o4lv": "var(--token-7858affe-da45-415e-8fc9-01499434ab14, rgb(198, 200, 201))", "--framer-paragraph-spacing": "0px"}} text={RoarXnRWq} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-06ZNu[data-border=\"true\"]::after, .framer-06ZNu [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-06ZNu.framer-ibhy7q, .framer-06ZNu .framer-ibhy7q { display: block; }", ".framer-06ZNu.framer-v8uhv9 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 2px; height: min-content; justify-content: center; overflow: visible; padding: 7px 8px 7px 8px; position: relative; width: min-content; }", ".framer-06ZNu .framer-12i2j1r { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-06ZNu.framer-v8uhv9 { gap: 0px; } .framer-06ZNu.framer-v8uhv9 > * { margin: 0px; margin-left: calc(2px / 2); margin-right: calc(2px / 2); } .framer-06ZNu.framer-v8uhv9 > :first-child { margin-left: 0px; } .framer-06ZNu.framer-v8uhv9 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 97
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"RoarXnRWq":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerLprx3E6L9: React.ComponentType<Props> = withCSS(Component, css, "framer-06ZNu") as typeof Component;
export default FramerLprx3E6L9;

FramerLprx3E6L9.displayName = "cursor-badge";

FramerLprx3E6L9.defaultProps = {height: 26, width: 97};

addPropertyControls(FramerLprx3E6L9, {RoarXnRWq: {defaultValue: "Free & Paid", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerLprx3E6L9, [{family: "Clash Grotesk Semibold", url: "https://framerusercontent.com/assets/MZ0MkELZyrimjDGDUZ6vtkl8k8Q.woff2"}])